import React, { useCallback, useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { Div, NavigationHeader } from '@driftt/tide-core';

import { fetchTranscodedGif,fetchVideoData } from 'api';
import PageBreadcrumbs from 'components/pageBreadcrumbs';
import VideoTitle from 'components/videoTitle';

import CopyGifButton from './CopyGifButton';
import GifPreview from './GifPreview';
import OptionsMenuButton from './OptionsMenuButton';

import './ShareGifPage.css';

function ShareGifPage({ videoId }) {
  const [gif, setGif] = useState();
  const [gifSrc, setGifSrc] = useState();
  const [hadTranscodeError, setHadTranscodeError] = useState(false);

  useEffect(() => {
    fetchVideoData(videoId)
      .then((data) => {
        setGif(data);
        setGifSrc(data.videoSources.find((source) => source.type === 'video/gif')?.src);
        document.title = `Drift | ${data.title}`;
      })
      .catch((err) => {
        Error(err);
      });
  }, [videoId]);

  const fetchTranscodeData = useCallback(async () => {
    try {
      setGifSrc(await fetchTranscodedGif({ videoId }));
    } catch (e) {
      Error(e);
      setHadTranscodeError(true);
    }
  }, [videoId]);

  useEffect(() => {
    if (!gifSrc) {
      fetchTranscodeData();
    }
  }, [gifSrc, fetchTranscodeData]);

  return (
    <Div className="share-gif-page">
      <NavigationHeader>
        <PageBreadcrumbs />
        <div className="share-gif-page-title-and-options">
          {gif?.title && <VideoTitle videoId={videoId} initialValue={gif?.title} />}
          <div>
            <CopyGifButton disabled={!gifSrc} gifSrc={gifSrc} />
            <OptionsMenuButton isDownloadDisabled={!gifSrc} videoId={videoId} />
          </div>
        </div>
      </NavigationHeader>
      <main className="share-gif-page-content">
        <GifPreview gifSrc={gifSrc} hadTranscodeError={hadTranscodeError} />
      </main>
    </Div>
  );
}

ShareGifPage.propTypes = {
  videoId: PropTypes.number.isRequired,
};

export default ShareGifPage;
