import * as React from 'react';
import { useEffect,useState } from 'react';

// @ts-ignore
import recordVideTooltipPlus from 'assets/images/recordVideoTooltipPlus.png'

import { Button } from '@driftt/tide-core';
import { FeatureTooltip } from '@driftt/tide-tooltips';

import { getTranscript } from 'api';
import { EXTENSION_URL } from 'utils/constants';
import { checkForNewExtension, sendTranscriptToExtension } from 'utils/extensionUtils';

const RecreateButton: React.FC<{ videoId: number; videoTitle: string }> = ({
  videoId,
  videoTitle,
}) => {
  const [hasExtension, setHasExtension] = useState<boolean>(false);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  useEffect(() => {
    checkForNewExtension().then((result) => {
      setHasExtension(result);
    });
  }, []);

  const dispatchTranscript = async () => {
    const transcript = await getTranscript(videoId);
    sendTranscriptToExtension(transcript, videoTitle);
  };

  return (
    <div>
      <FeatureTooltip
        className="record-video-education"
        showTooltip={showTooltip}
        onClose={() => setShowTooltip(false)}
        tooltipSide="top"
        tooltipCaretLocation="right"
        headerCopy="You’ll need the Drift Video extension to recreate this video"
        bodyCopy="Make sure you're in a Chrome browser and download Drift Video to get started recording."
        mediaComponent={
          <img src={recordVideTooltipPlus} alt="recordVideTooltipPlus" />
        }
        buttonInfoArray={[
          {
            copy: 'Get the extension',
            href: EXTENSION_URL,
          },
        ]}
      >
      <Button
        className="recreate-video"
        type="primary"
        onClick={() => (hasExtension ? dispatchTranscript() : setShowTooltip(true))}
      >
        Recreate this video
      </Button>
      </FeatureTooltip>
    </div>
  );
};

export default RecreateButton;
