import React, { useContext, useEffect, useState } from 'react';

import * as Sentry from '@sentry/browser';
import PropTypes from 'prop-types';

import { CircleLoader } from '@driftt/tide-core';

import { fetchAssociationToVideo, updateUser } from 'api';
import MetricsContext, { makeTrackCTA, makeTrackVideoQuality } from 'context/MetricsContext';
import { UserContext } from 'context/UserContext';
import UserCustomizationsProvider from 'context/UserCustomizationContext';
import VideoShareContext from 'context/VideoShareContext';
import { redirectToPlayerPage } from 'utils';

import HowToVideoPage from './HowToVideoPage';
import OnboardingSuccessOverlay from './OnboardingSuccessOverlay';
import ShareVideoPage from './ShareVideoPage';
import TopLevelErrorBoundary from './TopLevelErrorBoundary';

const SHARE_PAGE_LAYOUTS = {
  OWN_VIDEO: 'OWN_VIDEO',
  TEAM_VIDEO: 'TEAM_VIDEO',
  HOW_TO_VIDEO: 'HOW_TO_VIDEO',
};

if (window.__ENV__ === 'MASTER' || window.__ENV__ === 'PRODUCTION') {
  Sentry.init({
    // `video-share-page` project of `#video-team` team
    // https://sentry.io/organizations/driftt/issues/?project=2039603
    dsn: 'https://e4249b1e08fe46eabb3bb91dd93b574c@sentry.io/2039603',
    environment: window.__ENV__,
  });

  const trackPageView = () => {
    window.newrelic.addPageAction('REACT_SHARE_PAGE_VIEWED');
  };

  if (window.newrelic) {
    trackPageView();
  } else {
    window.onNewRelicLoaded = () => trackPageView();
  }
}

const ShareVideoApp = ({ videoId }) => {
  const { user, appLoading, userHasFeature } = useContext(UserContext);
  const [hasSubmittedQualityFeedback, setHasSubmittedQualityFeedback] = useState(false);
  const [pageLayout, setPageLayout] = useState(null);

  useEffect(() => {
    if (user && videoId) {
      if (!user.hasCompletedOnboarding) {
        updateUser({ userId: user.id, update: { hasCompletedOnboarding: true } });
      }

      fetchAssociationToVideo(videoId)
        .then(({ data: { isViewingOwnVideo, isViewingSameOrgVideo, isViewingHowToVideo } }) => {
          if (isViewingOwnVideo) {
            setPageLayout(SHARE_PAGE_LAYOUTS.OWN_VIDEO);
          } else if (userHasFeature('HAS_NEW_HOW_TO_PAGE') && isViewingHowToVideo) {
            setPageLayout(SHARE_PAGE_LAYOUTS.HOW_TO_VIDEO);
          } else if (
            userHasFeature('HAS_TEAMMATE_SHARE_PAGE') &&
            !isViewingOwnVideo &&
            isViewingSameOrgVideo
          ) {
            setPageLayout(SHARE_PAGE_LAYOUTS.TEAM_VIDEO);
          } else {
            redirectToPlayerPage();
          }
        })
        .catch((err) => {
          Error('authentication error:', err);
          redirectToPlayerPage();
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, videoId]);

  if (!appLoading && !user) {
    redirectToPlayerPage();
  }

  return (
    <>
      {(!pageLayout || appLoading) && (
        <div className="loader-container">
          <CircleLoader size="large" colorMode="dark" />
        </div>
      )}
      {!(!pageLayout || appLoading) && user && (
        <MetricsContext.Provider
          value={{
            addBreadcrumb: (breadcrumb) => {
              Sentry.addBreadcrumb({ level: 'info', ...breadcrumb });
            },
            trackCTA: makeTrackCTA(videoId, window.__ENV__),
            trackVideoQuality: makeTrackVideoQuality(videoId, window.__ENV__),
          }}>
          <VideoShareContext.Provider
            value={{
              hasSubmittedQualityFeedback,
              setHasSubmittedQualityFeedback,
            }}>
            <TopLevelErrorBoundary>
              {pageLayout === SHARE_PAGE_LAYOUTS.OWN_VIDEO && (
                <>
                  <UserCustomizationsProvider>
                    <ShareVideoPage videoId={videoId} />
                  </UserCustomizationsProvider>
                  {!user.hasCompletedOnboarding && <OnboardingSuccessOverlay />}
                </>
              )}
              {pageLayout === SHARE_PAGE_LAYOUTS.TEAM_VIDEO && (
                <HowToVideoPage videoId={videoId} isHowToVideo={false} />
              )}
              {pageLayout === SHARE_PAGE_LAYOUTS.HOW_TO_VIDEO && (
                <HowToVideoPage videoId={videoId} isHowToVideo={true} />
              )}
            </TopLevelErrorBoundary>
          </VideoShareContext.Provider>
        </MetricsContext.Provider>
      )}
    </>
  );
};

ShareVideoApp.propTypes = {
  videoId: PropTypes.string,
};

export default ShareVideoApp;
