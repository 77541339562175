// @ts-nocheck TODO: remove this when there is time
import * as React from 'react';
import { useContext, useEffect } from 'react';

import driftLogo from 'assets/svg/drift-video-logo-new.svg';

import { DefaultContainer, Div,H1, P } from '@driftt/tide-core';

import UserNotice from 'components/userNotice';
import VideoPageBanner from 'components/videoPageBanner';
import VideoPlayer from 'components/videoPlayer';
import { UserContext } from 'context/UserContext';
import useToastNotifications from 'hooks/useToastNotifications';
import useUserCustomization from 'hooks/useUserCustomization';
import useVideoData from 'hooks/useVideoData';
import { redirectToPlayerPage } from 'utils';
import { segmentPage } from 'utils/metrics';

import PageHeaderButtons from '../PageHeaderButtons';

import RecreateButton from './RecreateButton';

import './style.css';

function HowToVideoPage({ videoId, isHowToVideo }: { videoId: number; isHowToVideo: boolean }) {
  const {
    videoData,
    isAuthorized,
    playerOptions,
    videoDataLoading,
  } = useVideoData({ videoId });

  const { userHasFeature, createUrlPath, isFreeVideoUser } = useContext(UserContext);

  if (!isAuthorized) {
    redirectToPlayerPage();
  }

  const { ToastContainer } = useToastNotifications();
  const { userCustomization } = useUserCustomization();

 
  useEffect(() => {
    const pageName = isHowToVideo ? 'How to share page' : 'Teammate share page';
    segmentPage(pageName);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    !videoDataLoading && (
      <Div className="share-video-page how-to-video">
        <ToastContainer className="video-share__toast-container" />
        <UserNotice location="share" />
        {isHowToVideo ? (
          <VideoPageBanner
            backButtonText={'Back to How to use Drift Video'}
            onBackClick={() => (window.location.href = createUrlPath('/video/how-to/'))}
            description={"You're viewing a Drift tutorial!"}
          />
        ) : (
          <VideoPageBanner
            backButtonText={'Back to library'}
            onBackClick={() => (window.location.href = createUrlPath('/video'))}
            description={"You're viewing a teammates video!"}
          />
        )}
        <Div className="logo-header-banner">
          <img src={driftLogo} alt="video-drift-logo" />
        </Div>
        <DefaultContainer>
          <Div className="drift-video content-container">
            {
              <VideoPlayer
                userCustomizations={userCustomization}
                playerOptions={playerOptions}
                videoData={videoData}
                isMobile={false}
              />
            }
            <Div className="video-data-container">
              <Div className="video-info">
                <H1>{videoData?.title}</H1>
                <Div>
                  <P>
                    {videoData?.videoCreator?.name} &bull; {videoData?.createDate}
                  </P>
                </Div>
              </Div>
              <Div className="video-actions">
                <PageHeaderButtons
                  isDownloadReady={Boolean(videoData && videoData?.videoSources?.length > 0)}
                  videoId={videoId}
                  publicUrl={videoData?.publicUrl}
                  thumbnailUrl={videoData?.thumbnailUrl}
                  videoTitle={videoData?.title}
                  isVideoPrivate={videoData?.isPrivate}
                  showMoreOptions={false}
                  showDownloadButton={!isFreeVideoUser()}
                />
                {userHasFeature('HAS_RECREATE_THIS_VIDEO') && (
                  <RecreateButton videoId={videoId} videoTitle={videoData?.title} />
                )}
              </Div>
            </Div>
          </Div>
        </DefaultContainer>
      </Div>
    )
  );
}

export default HowToVideoPage;
