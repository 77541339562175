import React from 'react';

import { H4, P } from '@driftt/tide-core';

import { generateStaticUrls } from 'utils';

import './OnboardingSuccessOverlay.css';

function OnboardingSuccessOverlay() {
  return (
    <div className="drift-video-onboarding-success-overlay">
      <img src={generateStaticUrls('/images/drift/drift-video-success.svg')} alt="" />
      <H4 className="success-overlay-header">Sweet - your first Drift video!</H4>
      <P>We saved it to your library for you.</P>
    </div>
  );
}

export default OnboardingSuccessOverlay;
