import React from 'react';
import { CircleLoader } from '@driftt/tide-core';

import './VideoLoadingPlaceholder.css';

function VideoLoadingPlaceholder() {
  return (
    <div className="video-loading-placeholder">
      <CircleLoader size="large" />
    </div>
  );
}

export default VideoLoadingPlaceholder;
