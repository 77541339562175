import React from 'react';

import PropTypes from 'prop-types';

import { Button} from '@driftt/tide-core';

import './CustomerPreviewButton.css';

function CustomerPreviewButton({ publicUrl }) {
  const handleClick = () => window.open(publicUrl, '_blank');

  return (
    <Button
      className="customer-preview-button"
      type="tertiary"
      onClick={handleClick}
      disabled={!publicUrl}
    >
      Customer preview
    </Button>
  );
}

CustomerPreviewButton.propTypes = {
  publicUrl: PropTypes.string,
};

export default CustomerPreviewButton;
